import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { InfoText } from '@bojagi/pablo/Typography';
import { getSpacing } from '@bojagi/pablo/styleHelpers';
import { DynamicLink } from './DynamicLink';

export const FooterNav = styled.ul`
  margin: 0 ${getSpacing(6)};
  padding: 0;
  list-style: none;
`;

const StyledLink = styled(DynamicLink)`
  color: inherit;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const StyledLi = styled.li`
  margin: 0 0 ${getSpacing(3)};
`;

export interface FooterNavItemProps {
  children: ReactNode;
  onClick?: () => void;
  to: string;
}

export const FooterNavItem = ({ children, onClick, to }: FooterNavItemProps) => (
  <StyledLi>
    <InfoText>
      <StyledLink onClick={onClick} to={to}>
        {children}
      </StyledLink>
    </InfoText>
  </StyledLi>
);
