import React, { useState, useEffect, ReactNode, useCallback } from 'react';
import { PabloThemeProvider } from '@bojagi/pablo/theme';
import { getColor, getSpacing } from '@bojagi/pablo/styleHelpers';
import { EventListenerProvider, StatusType, EventHandlerFn } from '@bojagi/react-event';
import styled, { css, createGlobalStyle } from 'styled-components';
import { CookieBanner } from '@palmabit/react-cookie-law';
import { Header } from './Header';
import { Footer } from './Footer';
import { AnimationPlaybackProvider } from '../context/animationPlaybackContext';
import { allowGaCookie } from '../utils/ga';

const GlobalStyle = createGlobalStyle`
  body, html {
    padding: 0;
    margin: 0;
    overflow-x: hidden !important;
    
    font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;
  }

  a {
    color: ${getColor('brand', 'main')};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }

  ::selection {
    color: ${getColor('brand', 'contrastText')};
    background: ${getColor('brand', 'dark')};
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      
    }
  }

  .react-cookie-law-dialog {
    background-color: ${getColor('brand', 'darkest')};
    color: #fff;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 100000;
    padding: 10px;

    & a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .react-cookie-law-msg p {
    line-height: 1.4em;
    margin: 0.5em 0 1em;
  }

  .react-cookie-law-manage-btn,
  .react-cookie-law-save-btn,
  .react-cookie-law-accept-btn {
    background-color: ${getColor('brand', 'main')};
    border: 1px solid ${getColor('brand', 'main')};
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-bottom: 0.3em;

    &:hover {
      background-color: ${getColor('brand', 'dark')};
    }
  }

  .react-cookie-law-save-btn,
  .react-cookie-law-manage-btn {
    margin-right: ${getSpacing(6)};
  }

  .react-cookie-law-manage-btn {
    background-color: transparent;
    border: 1px solid ${getColor('brand', 'light')};

    &:hover {
      background-color: ${getColor('brand', 'dark')};
    }
  }
`;

const Main = styled.main`
  min-height: 100vh;
`;

const componentConfig = {
  button: {
    base: {
      borderRadius: '8px',
    },
    sizes: {
      small: {
        padding: '8px 24px',
      },
      medium: {
        padding: '12px',
      },
    },
  },
  typography: {
    styles: {
      headline: css`
        font-weight: 600;
      `,
      title: css`
        font-weight: 700;
      `,
      subtitle: css`
        font-weight: 600;
      `,
    },
  },
};

const theme = {
  typography: {
    button: {
      fontSize: '1.125rem',
    },
    headline: {
      fontSize: '2.25rem',
      marginBottom: '0.833333333em',
    },
    title: {
      fontSize: '1.75rem',
      marginBottom: '0.6em',
    },
    subtitle: {
      fontSize: '1.25rem',
      marginBottom: '0.7em',
    },
    paragraph: {
      fontSize: '1rem',
      lineHeight: '1.5em',
    },
    info: {
      fontSize: '0.875em',
    },
  },
};

export interface LayoutProps {
  showLogo?: boolean;
  children: ReactNode;
}

export const Layout = ({ showLogo = false, children }: LayoutProps) => {
  const [playAnimations, setPlayAnimations] = useState(true);

  useEffect(() => {
    const callback = () => {
      setPlayAnimations(document.visibilityState === 'visible');
      // This is to enforce reflow so the animations are restarted
      // eslint-disable-next-line no-unused-expressions
      window.scrollX;
    };
    window.addEventListener('visibilitychange', callback);
    return () => window.removeEventListener('visibilitychange', callback);
  }, []);

  const handleGaEvents = useCallback<EventHandlerFn>((eventName, context, logLevel) => {
    const { gtag } = window as any;

    if ((logLevel === StatusType.info || logLevel === StatusType.warn) && !!gtag) {
      gtag('event', eventName, context);
    }
  }, []);

  return (
    <EventListenerProvider eventHandlers={[handleGaEvents]}>
      <AnimationPlaybackProvider play={playAnimations}>
        <PabloThemeProvider theme={theme} componentStyles={componentConfig}>
          <GlobalStyle />
          <Header showLogo={showLogo} />
          <Main>{children}</Main>
          <Footer />
          <CookieBanner
            message={
              <p>This site uses cookies to provide basic functionality and to analyse usage.</p>
            }
            onAcceptStatistics={() => {
              allowGaCookie();
            }}
            showPreferencesOption={false}
            showMarketingOption={false}
            policyLink="/privacy"
            styles={{
              dialog: {},
              message: {},
              optionLabel: { marginLeft: 24, marginRight: 10, lineHeight: '1.6em' },
              policy: { fontSize: 14, marginLeft: 8, color: '#fff', textDecoration: 'underline' },
              button: {},
            }}
          />
        </PabloThemeProvider>
      </AnimationPlaybackProvider>
    </EventListenerProvider>
  );
};
