import React, { useRef, useEffect, createContext, useContext, ReactNode, useCallback } from 'react';
import { EventHandlerFn } from './types';

export type EventListenerRegisterFn = (eventName: string, eventCb: EventHandlerFn) => () => void;
export type EventTriggerFn = (eventName: string) => void;

export const eventListenerContext = createContext<{
  addEventListener: EventListenerRegisterFn;
  triggerEvent: EventTriggerFn;
}>({
  addEventListener: () => () => {},
  triggerEvent: () => {},
});

export const useEventListenerContext = () => useContext(eventListenerContext);

export interface EventListenerProviderProps {
  children: ReactNode;
  eventHandlers?: EventHandlerFn[];
}

export const EventListenerProvider = ({
  children,
  eventHandlers = [],
}: EventListenerProviderProps) => {
  const listenerRegistryRef = useRef<Record<string, EventHandlerFn[]>>({});

  const triggerEvent = useCallback<EventHandlerFn>((eventName, context, logLevel) => {
    const listenerRegistry = listenerRegistryRef.current;
    const globalListeners = listenerRegistry.global || [];
    const specificListeners = listenerRegistry[eventName] || [];
    [...globalListeners, ...specificListeners].forEach(cb => cb(eventName, context, logLevel));
  }, []);

  const addEventListener = useCallback((eventName: string, listenerFn: EventHandlerFn) => {
    listenerRegistryRef.current = {
      ...listenerRegistryRef.current,
      [eventName]: [...(listenerRegistryRef.current[eventName] || []), listenerFn],
    };

    return () => {
      const arr = [...(listenerRegistryRef.current[eventName] || [])];
      const index = arr.findIndex(item => item === listenerFn);
      arr.splice(index, 1);
      listenerRegistryRef.current = {
        ...listenerRegistryRef.current,
        [eventName]: arr,
      };
    };
  }, []);

  useEffect(() => {
    const dissolvers = eventHandlers.map(handler => addEventListener('global', handler));
    return () => {
      dissolvers.forEach(dissolver => dissolver());
    };
  });

  return (
    <eventListenerContext.Provider value={{ addEventListener, triggerEvent }}>
      {children}
    </eventListenerContext.Provider>
  );
};

export function useEventListener(
  eventIdentifiers: string[],
  onEvent: EventHandlerFn,
  dependencies: unknown[]
) {
  const { addEventListener } = useEventListenerContext();
  useEffect(() => {
    const eventDisposer = eventIdentifiers.map(event => addEventListener(event, onEvent));

    return () => {
      eventDisposer.forEach(item => item());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEventListener, ...dependencies]);
}
