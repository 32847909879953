import React from 'react';
import styled from 'styled-components';
import { Box } from '@bojagi/pablo/Box';
import { Image } from '@bojagi/pablo/Image';
import { InfoText } from '@bojagi/pablo/Typography';
import { useLogger } from '@bojagi/react-event';
import { FooterNav, FooterNavItem } from './FooterNav';
import { DynamicLink } from './DynamicLink';

import slackLogo from '../images/footer/logo_slack.svg';
import twitterLogo from '../images/footer/logo_twitter.svg';
import linkedInLogo from '../images/footer/logo_linkedin.svg';
import mediumLogo from '../images/footer/logo_medium.svg';
import githubLogo from '../images/footer/logo_github.svg';

const StyledFooter = styled.footer`
  padding: 22px;
  background-color: #efefef;
`;

const HeartIcon = () => (
  <svg width="14px" height="13px" viewBox="0 0 14 13">
    <g transform="translate(-67.000000, -5.000000)" fill="#FF1657">
      <path d="M73.9999878,7.71721412 C73.3737393,6.54648952 72.139254,5.75002134 70.7187435,5.75002134 C68.6649382,5.75002134 67,7.41495953 67,9.46876486 C67,9.49949797 67.001098,9.53002657 67.003268,9.5603499 C67.0010957,9.60129008 67,9.64366549 67,9.68751448 C67,14.5520232 73.9999878,18 73.9999878,18 C73.9999878,18 80.9781861,14.4559998 80.9999756,9.68751448 C81.0001753,9.64380978 80.9991479,9.60166025 80.9969168,9.56102295 C80.998948,9.5305315 80.9999756,9.49977908 80.9999756,9.46876486 C80.9999756,7.41495953 79.3350374,5.75002134 77.2812321,5.75002134 C75.8607216,5.75002134 74.6262363,6.54648952 73.9999878,7.71721412 Z" />
    </g>
  </svg>
);

interface SocialLogoProps {
  to: string;
  src: string;
  alt: string;
  eventLabel?: string;
  onClick?: () => void;
}

const SocialLogo = ({ to, src, alt, onClick }: SocialLogoProps) => (
  <Box mx={3}>
    <DynamicLink to={to} onClick={onClick}>
      <Image src={src} alt={alt} width={28} height={28} />
    </DynamicLink>
  </Box>
);

export const Footer = () => {
  const naviItemLogger = useLogger({
    section: 'footer',
    action: 'click',
    component: 'navItem',
  });

  const socialLogoLogger = useLogger({
    section: 'footer',
    action: 'click',
    component: 'socialLogo',
  });

  return (
    <StyledFooter>
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        maxWidth={1100}
        mx="auto"
      >
        <Box mb={[7, 0]}>
          <InfoText mb={5}>© Bojagi 2021, All Rights Reserved</InfoText>
          <Box as="nav" display="flex" mx={-6}>
            <FooterNav>
              <FooterNavItem onClick={naviItemLogger.createHandler({ label: 'blog' })} to="/blog">
                Visit our blog
              </FooterNavItem>
              <FooterNavItem onClick={naviItemLogger.createHandler({ label: 'team' })} to="/team">
                Team
              </FooterNavItem>
              <FooterNavItem
                onClick={naviItemLogger.createHandler({ label: 'imprint' })}
                to="/imprint"
              >
                Imprint
              </FooterNavItem>
              <FooterNavItem onClick={naviItemLogger.createHandler({ label: 'terms' })} to="/terms">
                Terms of Service
              </FooterNavItem>
              <FooterNavItem
                onClick={naviItemLogger.createHandler({ label: 'privacy' })}
                to="/privacy"
              >
                Privacy
              </FooterNavItem>
            </FooterNav>
            <FooterNav>
              <FooterNavItem
                onClick={naviItemLogger.createHandler({ label: 'storybook' })}
                to="/storybook"
              >
                Storybook
              </FooterNavItem>
              <FooterNavItem
                onClick={naviItemLogger.createHandler({ label: 'designerPage' })}
                to="/for-designers"
              >
                The Designer Page
              </FooterNavItem>
              <FooterNavItem
                onClick={naviItemLogger.createHandler({ label: 'developerPage' })}
                to="/for-developers"
              >
                The Developer Page
              </FooterNavItem>
              <FooterNavItem
                onClick={naviItemLogger.createHandler({ label: 'ceoPage' })}
                to="/for-ceos"
              >
                The CEO Page
              </FooterNavItem>
            </FooterNav>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <InfoText mb={5}>
            Made with <HeartIcon /> in Berlin
          </InfoText>
          <Box display="flex" mx={-3}>
            <SocialLogo
              to="/slack"
              eventLabel="Slack Link"
              onClick={socialLogoLogger.createHandler({ label: 'slack' })}
              src={slackLogo}
              alt="Logo Slack"
            />
            <SocialLogo
              to="https://twitter.com/bojagiapp"
              eventLabel="Twitter Link"
              alt="Logo Twitter"
              onClick={socialLogoLogger.createHandler({ label: 'twitter' })}
              src={twitterLogo}
            />
            <SocialLogo
              to="https://www.linkedin.com/company/28921823"
              eventLabel="LinkedIn Link"
              alt="Logo LinkedIn"
              onClick={socialLogoLogger.createHandler({ label: 'linkedIn' })}
              src={linkedInLogo}
            />
            <SocialLogo
              to="https://medium.com/bojagi"
              eventLabel="Medium Link"
              alt="Logo Medium"
              onClick={socialLogoLogger.createHandler({ label: 'medium' })}
              src={mediumLogo}
            />
            <SocialLogo
              to="https://www.github.com/bojagi"
              eventLabel="GitHub Link"
              onClick={socialLogoLogger.createHandler({ label: 'github' })}
              alt="Logo GitHub"
              src={githubLogo}
            />
          </Box>
        </Box>
      </Box>
    </StyledFooter>
  );
};
