import * as React from 'react';
import { useIdentifiers } from './identifierContext';
import { useEventListenerContext } from './eventListenerContext';
import { EventHandlerFn, LoggerContextOptions, StatusType } from './types';

export type LogContextArgs = LoggerContextOptions & Record<string, any>;

type LoggerContextProps = Omit<LoggerContextOptions, 'action'> &
  Record<string, any> & {
    uniqueKey?: any;
    logImpression?: boolean;
    children: React.ReactNode;
  };

export const loggerContext = React.createContext<LoggerContextOptions>({});

function useLoggerContext() {
  return React.useContext(loggerContext);
}

export function LoggerContext({
  children,
  logImpression,
  uniqueKey,
  ...context
}: LoggerContextProps) {
  const domRef = React.useRef<HTMLDivElement>(null);
  const parentContext = useLoggerContext();
  const [loggedImpression, setLoggedImpression] = React.useState(false);
  const [isInViewPort, setIsInViewPort] = React.useState(false);
  const contextValues = Object.values(context);
  /* eslint-disable react-hooks/exhaustive-deps */
  const extendedContext = React.useMemo(() => ({ ...parentContext, ...context }), [
    parentContext,
    ...contextValues,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */
  const logger = useLogger(extendedContext);

  // Reset logged impression when unique key changes
  React.useEffect(() => {
    setLoggedImpression(false);
  }, [uniqueKey]);

  const observerCallback = React.useCallback(
    entries => {
      const entry = entries[0];
      if (entry && isInViewPort !== entry.isIntersecting) {
        setIsInViewPort(entry.isIntersecting);
      }
    },
    [isInViewPort]
  );

  React.useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    });
    const wrappedDOMElements = (Array.from(
      domRef?.current?.childNodes || []
    ) as unknown[]) as Element[];
    const firstVisibleElement = wrappedDOMElements.find((el: any) => el.offsetParent !== null);
    if (firstVisibleElement) {
      observer.observe(firstVisibleElement);
    }

    return () => observer.disconnect();
  }, [observerCallback]);

  React.useEffect(() => {
    if (logImpression && !loggedImpression && isInViewPort) {
      logger.info({ action: 'impression' });
      setLoggedImpression(true);
    }
  }, [logger, loggedImpression, isInViewPort, logImpression]);

  return (
    <loggerContext.Provider value={extendedContext}>
      <div style={{ display: 'contents' }} ref={domRef}>
        {children}
      </div>
    </loggerContext.Provider>
  );
}

export function withLoggerContext(contextProps: Omit<LoggerContextProps, 'children'>) {
  return Component => props => (
    <LoggerContext {...contextProps}>
      <Component {...props} />
    </LoggerContext>
  );
}

interface CreateHandlerOptions extends Record<string, any> {
  level?: StatusType;
}

interface UseLoggerOutput extends Record<StatusType, (options: LogContextArgs) => void> {
  createHandler: (options: CreateHandlerOptions) => () => void;
}

export function useLogger(context?: LoggerContextOptions): UseLoggerOutput {
  const parentLoggerContext = useLoggerContext();
  const identifier = useIdentifiers();
  const combinedContext = React.useMemo(
    () => ({ ...parentLoggerContext, ...(context || {}), identifier }),
    [parentLoggerContext, identifier, context]
  );
  const { triggerEvent } = useEventListenerContext();
  return React.useMemo(
    () => ({
      debug: log.bind({}, 'debug', combinedContext, triggerEvent),
      info: log.bind({}, 'info', combinedContext, triggerEvent),
      warn: log.bind({}, 'warn', combinedContext, triggerEvent),
      error: log.bind({}, 'error', combinedContext, triggerEvent),
      createHandler: ({ level = StatusType.info, ...createHandlerOptions }) => () => {
        log(level, combinedContext, triggerEvent, createHandlerOptions);
      },
    }),
    [combinedContext, triggerEvent]
  );
}

export function withLogger(context: LoggerContextOptions = {}) {
  return Component => props => {
    const logger = useLogger(context);
    return <Component {...props} logger={logger} />;
  };
}

export function log(
  logLevel: StatusType,
  baseContext: LoggerContextOptions,
  triggerEvent: EventHandlerFn,
  context: LoggerContextOptions & Record<string, any>
) {
  const combinedContext: LoggerContextOptions & Record<string, any> = {
    ...baseContext,
    ...context,
  };
  const actionPath = [
    combinedContext.section,
    combinedContext.page,
    combinedContext.area,
    combinedContext.component,
    combinedContext.action,
  ]
    .filter(a => !!a)
    .join('.');

  triggerEvent(actionPath, combinedContext, logLevel);
}
