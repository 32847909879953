import React from 'react';
import { Link, GatsbyLinkProps } from 'gatsby';

export function DynamicLink(props: GatsbyLinkProps<{}>) {
  const isExternalLink = props.to.startsWith('https://');
  const Component = isExternalLink ? 'a' : Link;
  const targetProps = isExternalLink
    ? {
        href: props.to,
        target: '_blank',
      }
    : {
        to: props.to,
      };
  return <Component {...props} {...targetProps} />;
}
