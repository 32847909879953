export type EventHandlerFn = (
  eventName: string,
  context?: LoggerContextOptions & Record<string, any>,
  logLevel?: StatusType
) => void;

export interface LoggerContextOptions {
  sessionId?: string;
  section?: string;
  page?: string;
  area?: string;
  component?: string;
  action?: string;
}

export enum StatusType {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}
