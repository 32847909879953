import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export function Seo({
  description = '',
  lang = 'en',
  meta = [],
  title,
  route,
  ignoreTemplate = false,
  socialPreviewImage = 'bojagi-social-preview.jpg',
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const image = `https://bojagi.io/images/${socialPreviewImage}`;
  const url = `https://bojagi.io/${route}`;

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle && !ignoreTemplate ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '675' },
        { property: 'og:image', content: image },
        { property: 'og:url', content: url },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: `@bojagiapp`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: url },
        {
          name: 'twitter:image',
          content: image,
        },
        {
          name: 'keywords',
          content: 'DesignOps, React, Components, Review, Storybook, Design, UI, UX',
        },
      ].concat(meta)}
    />
  );
}
