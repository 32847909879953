import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@bojagi/pablo/Button';
import { getSpacing } from '@bojagi/pablo/styleHelpers';
import { DynamicLink } from './DynamicLink';

export const HeaderNav = styled.ul`
  margin: 0 -18px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
`;

export const StyledLi = styled.li`
  margin: 0 ${getSpacing(5)};
`;

export interface HeaderNavItemProps {
  children: ReactNode;
  to: string;
  onClick?: () => void;
}

export const HeaderNavItem = ({ children, to, onClick }: HeaderNavItemProps) => (
  <StyledLi>
    <Button
      color="plain"
      variant="text"
      size="small"
      as={DynamicLink}
      to={to}
      onClick={onClick}
      customStyles={{
        text: css`
          padding-left: ${getSpacing(4)};
          padding-right: ${getSpacing(4)};
          :hover {
            text-decoration: none;
          }
        `,
      }}
    >
      {children}
    </Button>
  </StyledLi>
);

export const HeaderNavHighlightItem = ({ children, to, onClick }: HeaderNavItemProps) => (
  <StyledLi>
    <Button
      as={DynamicLink}
      size="small"
      to={to}
      onClick={onClick}
      customStyles={{
        primary: css`
          & > span {
            font-weight: 600;
          }
          :hover {
            text-decoration: none;
          }
        `,
      }}
    >
      {children}
    </Button>
  </StyledLi>
);
