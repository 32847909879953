import React, { createContext, ReactNode, useContext } from 'react';

export const animationPlaybackContext = createContext(true);

export function useAnimationPlayback() {
  return useContext(animationPlaybackContext);
}

export interface AnimationPlaybackProviderProps {
  play: boolean;
  children: ReactNode;
}

export function AnimationPlaybackProvider({ play, children }) {
  return (
    <animationPlaybackContext.Provider value={play}>{children}</animationPlaybackContext.Provider>
  );
}
