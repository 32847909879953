import * as React from 'react';

export const identifierContext = React.createContext<Record<string, any>>({});

export function useIdentifiers() {
  return React.useContext(identifierContext);
}

export const IdentifierProvider = ({ children, ...identifiers }: Record<string, any>) => {
  const parentIdentifiers = useIdentifiers();
  return (
    <identifierContext.Provider
      value={{
        ...parentIdentifiers,
        ...identifiers,
      }}
    >
      {children}
    </identifierContext.Provider>
  );
};

export const withIdentifiers = (
  propsMapper: (props: Record<string, any>) => Record<string, any>
) => Component => props => (
  <IdentifierProvider {...propsMapper(props)}>
    <Component {...props} />
  </IdentifierProvider>
);
