import React, { useRef, useState } from 'react';
import { Link } from 'gatsby';
import { Menu, MenuItem } from '@bojagi/pablo/Menu';
import { useLogger } from '@bojagi/react-event';
import { ShowAbove, ShowBelow } from '@bojagi/pablo/Show';
import { IconButton } from '@bojagi/pablo/IconButton';
import styled from 'styled-components';
import { HeaderNav, HeaderNavItem, HeaderNavHighlightItem } from './HeaderNav';
import bojagiLogo from '../images/bojagi-logo.svg';

const StyledHeader = styled.header`
  background-color: white;
  z-index: 1000;
  padding: 18px;
  transition: box-shadow 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NAV_ITEMS = [
  {
    to: '/#features',
    label: 'Features',
    highlight: false,
  },
  {
    to: '/demo',
    label: 'Demo',
    highlight: false,
  },
  {
    to: '/pricing',
    label: 'Pricing',
    highlight: false,
  },
  {
    to: '/docs',
    label: 'Docs',
    highlight: false,
  },
  {
    to: 'https://medium.com/bojagi',
    label: 'Blog',
    highlight: false,
  },
  {
    to: '/login',
    label: 'Login',
    highlight: false,
  },
  {
    to: '/register',
    label: 'Register',
    highlight: true,
  },
];

const HamburgerIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="24">
    <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
  </svg>
);

interface LogoBoxProps {
  visible: boolean;
}

const LogoBox = styled(Link)<LogoBoxProps>`
  display: block;
  margin-right: 8px;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`;

export interface HeaderProps {
  showLogo?: boolean;
}

export function Header({ showLogo = false }: HeaderProps) {
  const headerRef = useRef<HTMLElement>(null);
  const logger = useLogger({
    section: 'header',
  });
  const [open, setOpen] = useState(false);
  return (
    <StyledHeader ref={headerRef}>
      <LogoBox to="/" visible={showLogo}>
        <img alt="Bojagi Logo" height="32" src={bojagiLogo} />
      </LogoBox>
      <ShowBelow breakpoint="md">
        <Menu
          open={open}
          placement="bottom-end"
          onClose={() => setOpen(false)}
          items={NAV_ITEMS.map(({ to, label }) => (
            <MenuItem as="a" key={to} href={to}>
              {label}
            </MenuItem>
          ))}
        >
          <IconButton onClick={() => setOpen(true)}>
            <HamburgerIcon />
          </IconButton>
        </Menu>
      </ShowBelow>
      <ShowAbove breakpoint="md">
        <nav>
          <HeaderNav>
            {NAV_ITEMS.map(({ to, label, highlight }) => {
              const NavItemComponent = highlight ? HeaderNavHighlightItem : HeaderNavItem;
              return (
                <NavItemComponent
                  onClick={logger.createHandler({
                    component: highlight && label ? label.toLowerCase() : 'navItem',
                    label,
                    action: 'click',
                  })}
                  key={to}
                  to={to}
                >
                  {label}
                </NavItemComponent>
              );
            })}
          </HeaderNav>
        </nav>
      </ShowAbove>
    </StyledHeader>
  );
}
