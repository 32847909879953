const GA_DISABLED_KEY = `ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`;

export const allowGaCookie = () => {
  window.gtag('consent', 'update', {
    ad_storage: 'denied',
    analytics_storage: 'granted',
  });
};

export const disableGA = () => {
  document.cookie = `${GA_DISABLED_KEY}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
  window[GA_DISABLED_KEY] = true;
  // eslint-disable-next-line no-alert
  alert('You are successfully opted out of our tracking in Google Analytics');
};
